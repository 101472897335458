/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'browser-safari': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8 16A8 8 0 108 0a8 8 0 000 16m.25-14.75v1.5a.25.25 0 01-.5 0v-1.5a.25.25 0 01.5 0m0 12v1.5a.25.25 0 11-.5 0v-1.5a.25.25 0 11.5 0M4.5 1.938a.25.25 0 01.342.091l.75 1.3a.25.25 0 01-.434.25l-.75-1.3a.25.25 0 01.092-.341m6 10.392a.25.25 0 01.341.092l.75 1.299a.25.25 0 11-.432.25l-.75-1.3a.25.25 0 01.091-.34zM2.28 4.408l1.298.75a.25.25 0 01-.25.434l-1.299-.75a.25.25 0 01.25-.434zm10.392 6l1.299.75a.25.25 0 11-.25.434l-1.3-.75a.25.25 0 01.25-.434zM1 8a.25.25 0 01.25-.25h1.5a.25.25 0 010 .5h-1.5A.25.25 0 011 8m12 0a.25.25 0 01.25-.25h1.5a.25.25 0 110 .5h-1.5A.25.25 0 0113 8M2.03 11.159l1.298-.75a.25.25 0 01.25.432l-1.299.75a.25.25 0 01-.25-.432zm10.392-6l1.299-.75a.25.25 0 11.25.433l-1.3.75a.25.25 0 01-.25-.434zM4.5 14.061a.25.25 0 01-.092-.341l.75-1.3a.25.25 0 01.434.25l-.75 1.3a.25.25 0 01-.342.091m6-10.392a.25.25 0 01-.091-.342l.75-1.299a.25.25 0 11.432.25l-.75 1.3a.25.25 0 01-.341.09zM6.494 1.415l.13.483a.25.25 0 11-.483.13l-.13-.483a.25.25 0 01.483-.13M9.86 13.972l.13.483a.25.25 0 11-.483.13l-.13-.483a.25.25 0 01.483-.13M3.05 3.05a.25.25 0 01.354 0l.353.354a.25.25 0 01-.353.353l-.354-.353a.25.25 0 010-.354m9.193 9.193a.25.25 0 01.353 0l.354.353a.25.25 0 11-.354.354l-.353-.354a.25.25 0 010-.353M1.545 6.01l.483.13a.25.25 0 11-.13.483l-.483-.13a.25.25 0 11.13-.482zm12.557 3.365l.483.13a.25.25 0 11-.13.483l-.483-.13a.25.25 0 11.13-.483m-12.863.436a.25.25 0 01.176-.306l.483-.13a.25.25 0 11.13.483l-.483.13a.25.25 0 01-.306-.177m12.557-3.365a.25.25 0 01.176-.306l.483-.13a.25.25 0 11.13.483l-.483.13a.25.25 0 01-.306-.177M3.045 12.944a.3.3 0 01-.029-.376l3.898-5.592a.3.3 0 01.062-.062l5.602-3.884a.278.278 0 01.392.392L9.086 9.024a.3.3 0 01-.062.062l-5.592 3.898a.3.3 0 01-.382-.034zm3.143 1.817a.25.25 0 01-.176-.306l.129-.483a.25.25 0 01.483.13l-.13.483a.25.25 0 01-.306.176M9.553 2.204a.25.25 0 01-.177-.306l.13-.483a.25.25 0 11.483.13l-.13.483a.25.25 0 01-.306.176"/>',
    },
});
